import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'list',
    loadChildren: './list/list.module#ListPageModule'
  },
  { path: 'live-streaming', loadChildren: './live-streaming/live-streaming.module#LiveStreamingPageModule' },
  { path: 'schedule', loadChildren: './schedule/schedule.module#SchedulePageModule' },
  { path: 'program', loadChildren: './program/program.module#ProgramPageModule' },
  { path: 'kids', loadChildren: './kids/kids.module#KidsPageModule' },
  { path: 'program-list', loadChildren: './program-list/program-list.module#ProgramListPageModule' },
  { path: 'program-detail', loadChildren: './program-detail/program-detail.module#ProgramDetailPageModule' },
  { path: 'player', loadChildren: './player/player.module#PlayerPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
